import React, { useState, useEffect } from 'react';
import './App.css';
import { FaHome, FaInfo, FaCogs, FaEnvelope } from 'react-icons/fa';

const App: React.FC = () => {
  const [clickPosition, setClickPosition] = useState<{ x: number, y: number } | null>(null);
  const [mousePosition, setMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  const handleMouseMove = (e: MouseEvent) => {
    setMousePosition({ x: e.clientX, y: e.clientY });
  };

  const handleClick = (e: React.MouseEvent) => {
    setClickPosition({ x: e.clientX, y: e.clientY });

    // Remove the click position after the animation duration
    setTimeout(() => setClickPosition(null), 300);
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  return (
    <div className="app-container" onClick={handleClick}>
      <header className="header">
        <h1>Responsive React App</h1>
      </header>
      <main className="main-content">
        <p>This is a simple responsive website.</p>
        <p>Resize the browser window to see the effect.</p>
        <p>Scroll down to see the fixed bottom menu.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Nulla facilisi. Morbi auctor turpis nec ex sagittis, non lobortis odio commodo. Vivamus vel nulla justo. Curabitur nec nulla nec nulla vehicula pretium ac quis justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent at fermentum magna. In ac vehicula arcu, non hendrerit ligula. Nulla vestibulum sapien vel bibendum fermentum. Nulla et ipsum vitae nunc facilisis vestibulum at sed metus. Curabitur sagittis nisi nec magna varius facilisis.</p>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus lacinia odio vitae vestibulum vestibulum. Cras venenatis euismod malesuada. Nulla facilisi. Morbi auctor turpis nec ex sagittis, non lobortis odio commodo. Vivamus vel nulla justo. Curabitur nec nulla nec nulla vehicula pretium ac quis justo. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Praesent at fermentum magna. In ac vehicula arcu, non hendrerit ligula. Nulla vestibulum sapien vel bibendum fermentum. Nulla et ipsum vitae nunc facilisis vestibulum at sed metus. Curabitur sagittis nisi nec magna varius facilisis.</p>
      </main>
      <footer className="footer">
        <nav className="bottom-menu">
          <ul>
            <li>
              <a 
                href="#home" 
                className={clickPosition ? 'active' : ''} 
                onClick={(e) => e.stopPropagation()}
              >
                <FaHome /><span>Home</span>
              </a>
            </li>
            <li>
              <a 
                href="#about" 
                className={clickPosition ? 'active' : ''} 
                onClick={(e) => e.stopPropagation()}
              >
                <FaInfo /><span>About</span>
              </a>
            </li>
            <li>
              <a 
                href="#services" 
                className={clickPosition ? 'active' : ''} 
                onClick={(e) => e.stopPropagation()}
              >
                <FaCogs /><span>Services</span>
              </a>
            </li>
            <li>
              <a 
                href="#contact" 
                className={clickPosition ? 'active' : ''} 
                onClick={(e) => e.stopPropagation()}
              >
                <FaEnvelope /><span>Contact</span>
              </a>
            </li>
          </ul>
        </nav>
      </footer>
      {clickPosition && (
        <div
          className="click-animation"
          style={{
            top: clickPosition.y,
            left: clickPosition.x,
          }}
        />
      )}
      <div
        className="mouse-tracker"
        style={{
          top: mousePosition.y,
          left: mousePosition.x,
        }}
      />
    </div>
  );
};

export default App;

